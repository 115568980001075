// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".organizationBadge-module__root--GzkLD{border-radius:48px;font-weight:600;font-size:.85em;width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./jsapp/js/components/header/organizationBadge.module.scss"],"names":[],"mappings":"AAEA,uCACE,kBAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA,CAAA,iBAAA","sourcesContent":["@use 'scss/colors';\n\n.root {\n  border-radius: 48px;\n  font-weight: 600;\n  font-size: .85em;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "organizationBadge-module__root--GzkLD"
};
export default ___CSS_LOADER_EXPORT___;
